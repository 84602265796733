import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BrandInfo from "./components/BrandInfo";
import BrandForm from "./components/BrandForm";
import {
  useRequestPost,
  useRequestGet,
  useRequestPatch,
} from "hooks/useRequest";
import { categoryConfig, getCategoryCodeByLabel } from "utils/SettingValue";
import { toast } from "react-toastify";

const Brand = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [value, setValue] = useState("");
  const [tags, setTags] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const postMutation = useRequestPost();
  const patchMutation = useRequestPatch();
  const brandData = useRequestGet(
    id ? `/api/brand/${id}` : null,
    id ? `brand-${id}` : null
  );

  useEffect(() => {
    if (id && brandData?.status === "success" && brandData?.data) {
      setIsEditMode(true);
      const {
        brandName,
        brandDescription,
        productCategoryNameKo,
        brandKeywords,
      } = brandData.data.data;
      setBrandName(brandName);
      setBrandDescription(brandDescription);
      setValue(getCategoryCodeByLabel(productCategoryNameKo));
      setTags(brandKeywords ? brandKeywords.split(",") : []);
    }
  }, [id, brandData?.status, brandData?.data]);

  const validateBrandName = (name) => {
    const regex = /^[가-힣a-zA-Z0-9!@#$%^&*()\-_+=<>? ]{2,20}$/;
    return regex.test(name);
  };

  const handleSubmit = async () => {
    if (!brandName) return toast.error("브랜드 이름을 입력해주세요!");
    if (!validateBrandName(brandName)) {
      return toast.error(
        "이름은 문자, 숫자, 특수문자(!@#$%^&*()-_+=<>?)를 포함한 2자 이상 20자 이하 여야 합니다."
      );
    }
    if (!value) return toast.error("카테고리를 선택해주세요!");
    if (!brandDescription) return toast.error("브랜드 설명을 입력해주세요!");
    if (tags.length === 0) return toast.error("태그를 입력해주세요!");

    const data = {
      brandName,
      brandDescription,
      brandKeywords: tags.join(","),
      productCategoryCode: value,
    };

    try {
      if (isEditMode) {
        await patchMutation.mutate({
          requestUrl: `/api/brand/${id}`,
          patchData: data,
        });
      } else {
        await postMutation.mutate({
          requestUrl: "/api/brand",
          postData: data,
        });
      }
    } catch (error) {
      console.error("Error submitting brand form:", error);
    }
  };

  // Handle success or pending state
  useEffect(() => {
    if (
      postMutation.status === "success" ||
      patchMutation.status === "success"
    ) {
      navigate("/setting?tab=brand", { state: { newBrandAdded: true } });
    } else if (
      postMutation.status === "pending" ||
      patchMutation.status === "pending"
    ) {
      console.log("브랜드 생성 중입니다..");
    }
  }, [postMutation.status, patchMutation.status, navigate]);

  // Utility to get category name
  const getCategoryNameByCode = (code) => {
    const category = categoryConfig.find(
      (item) => item.product_category_code === code
    );
    return category ? category.product_category_name_ko : "";
  };

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper">
        <div className="flex">
          <p className="after:content-['/'] after:ml-1 after:mr-1 text-text-gray">
            사전설정
          </p>
          <p className="after:content-['/'] after:ml-1 after:mr-1">
            <button
              onClick={() => navigate("/setting?tab=brand")}
              className="text-text-gray rounded-sm hover:bg-gray-lGray pl-1 pr-1"
            >
              브랜드 설정
            </button>
          </p>
          <p className="text-xl font-semibold mb-12">
            {isEditMode ? "브랜드 수정" : "브랜드 명"}
          </p>
        </div>
        <div className="m-auto w-[780px] flex flex-col gap-5">
          <BrandInfo
            brandDescription={brandDescription}
            brandName={brandName}
            tags={tags}
            value={getCategoryNameByCode(value)}
          />
          <BrandForm
            brandName={brandName}
            setBrandName={setBrandName}
            brandDescription={brandDescription}
            setBrandDescription={setBrandDescription}
            value={value}
            setValue={setValue}
            categoryConfig={categoryConfig}
            tags={tags}
            setTags={setTags}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Brand;
