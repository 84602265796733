export const OPEN_AI = {
    run: (projectSeq, contentSeq) => `/api/project/${projectSeq}/content/${contentSeq}/run`,
    freeChatRun: `/api/free-chat-completion/run`,
    completionRun: `/api/chat-completion/run`,
};

export const PLACE = {
    place: `/api/place`,
    placeSeq: (placeSeq) => `/api/place/${placeSeq}`,
    placeCategory: (placeCategory) => `/api/place/category/${placeCategory}`,
};

export const TEST_CONTROLLER = {
    naverMap: (targetUrl) => `/api/test/naver-map?targetUrl=${targetUrl}`,
};

export const EXTERNAL_API = {
    instantSearch: `/naver-map-proxy/p/api/search/instant-search`,
    proxedInstantSearch: `https://naver-proxy.cherrypick-ai-team.workers.dev/`,
};
