// hooks
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// common

import NavigateHeader from 'layout/NavigateHeader';
// templates
import Instagram from 'pages/Template/components/Instagram';
import NaverBlog from 'pages/Template/components/NaverBlog';
import TStory from 'pages/Template/components/TStory';
import LinkedinProfile from 'pages/Template/components/LinkedinProfile';
import ImageStudio from 'pages/Template/components/ImageStudio';
import Diary from 'pages/Template/components/Diary';
import DreamDiary from 'pages/Template/components/DreamDiary';
import ReflectionLetter from 'pages/Template/components/ReflectionLetter';
import Toast from 'pages/Template/components/Toast';
import InterviewQeustionsAnswers from 'pages/Template/components/InterviewQeustionsAnswers';
import CopyWritingIdea from 'pages/Template/components/CopyWritingIdea';
import ImprovedAIDAModelAD from 'pages/Template/components/ImprovedAIDAModelAD';
import ProductDetail from 'pages/Template/components/ProductDetail';
import GoogleAD from 'pages/Template/components/GoogleAD';
import MarketingPlanDraft from 'pages/Template/components/MarketingPlanDraft';
import BusinessPlanDraft from 'pages/Template/components/BusinessPlanDraft';
import AppPushMessage from 'pages/Template/components/AppPushMessage';
import PressRelease from 'pages/Template/components/PressRelease';
import RewriteArticle from 'pages/Template/components/RewriteArticle';
import Notice from 'pages/Template/components/Notice';
import TravelSchedule from 'pages/Template/components/TravelSchedule';
import HospitalBlog from 'pages/Template/components/HospitalBlog';

const Template = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const templatePageMatcher = (templateEN) => {
        switch (templateEN) {
            case 'instagram':
                return <Instagram templateInfo={state} />;
            case 'naverBlog':
                return <NaverBlog templateInfo={state} />;
            case 'tStory':
                return <TStory templateInfo={state} />;
            case 'linkedinProfile':
                return <LinkedinProfile templateInfo={state} />;
            case 'ImageStudio':
                return <ImageStudio templateInfo={state} />;
            case 'diary':
                return <Diary templateInfo={state} />;
            case 'dreamDiary':
                return <DreamDiary templateInfo={state} />;
            case 'reflectionLetter':
                return <ReflectionLetter templateInfo={state} />;
            case 'toast':
                return <Toast templateInfo={state} />;
            case 'interviewQeustionsAnswers':
                return <InterviewQeustionsAnswers templateInfo={state} />;

            case 'copyWritingIdea':
                return <CopyWritingIdea templateInfo={state} />;
            case 'improvedAIDAModelAD':
                return <ImprovedAIDAModelAD templateInfo={state} />;
            case 'productDetail':
                return <ProductDetail templateInfo={state} />;
            case 'googleAD':
                return <GoogleAD templateInfo={state} />;
            case 'marketingPlanDraft':
                return <MarketingPlanDraft templateInfo={state} />;

            case 'businessPlanDraft':
                return <BusinessPlanDraft templateInfo={state} />;
            case 'appPushMessage':
                return <AppPushMessage templateInfo={state} />;
            case 'pressRelease':
                return <PressRelease templateInfo={state} />;
            case 'rewriteArticle':
                return <RewriteArticle templateInfo={state} />;
            case 'notice':
                return <Notice templateInfo={state} />;
            case 'travelSchedule':
                return <TravelSchedule templateInfo={state} />;
            case 'hospitalBlog':
                return <HospitalBlog templateInfo={state} />;
            default:
                return;
        }
    };
    return (
        <div className="pb-10 wrapper">
            <div className="flex flex-wrap justify-center cherry-wrapper">
                <NavigateHeader />

                <div className="w-full max-w-screen-lg">{templatePageMatcher(state.templateEN)}</div>
            </div>
        </div>
    );
};

export default Template;
