// hooks
import { useEffect, useRef, useState } from "react";
import { useRequestGet, useRequestPost } from "hooks/useRequest";
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
import { useNavigate, useSearchParams } from "react-router-dom";
// commons
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import RecommandPlaceTitleModal from "pages/Template/components/Modals/RecommandPlaceTitleModal";
import TextArea from "components/TextArea";
import AITypeDropdown from "components/Dropdowns/AITypeDropdown";
import TimeLoadingModal from "pages/BehanceEditor/components/Modals/TimeLoadingModal";
import ShortageByteModal from "components/Modals/ShortageByteModal";
import SearchMapModal from "components/Modals/SearchMapModal";
import PlaceDropdown from "components/Dropdowns/PlaceDropdown";
// utils
import {
  objectiveCode,
  templateCode,
  toneCode,
  languageCode,
} from "utils/TemplateQuestions";
import { PLACE } from "utils/consts/apiEndpoint";
// icons
import { FaSquarePlus, FaSquareMinus } from "react-icons/fa6";
//images
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
import loadingImg from "assets/images/loadingImg.gif";
const HospitalBlog = ({ templateInfo }) => {
  const {
    cp_template_seq,
    instruction,
    template_code,
    template_name,
    template_plum,
  } = templateCode[templateInfo?.templateEN][0];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const getUserInfo = useRequestGet("/api/user", "userInfo");

  const getBrand = useRequestGet("/api/brand", "brand"); //getBrand.data.data.data
  const getTarget = useRequestGet("/api/target", "target"); //getBrand.data.data.data
  const AiTypeList = [
    { type: "gpt", title: "GPT", describe: "창의적인 텍스트 생성" },
    { type: "claude", title: "Claude", describe: "자연스러운 텍스트 생성" },
  ];
  const [isSearchMapModal, setIsSearchMapModal] = useState(false);
  const [isShortageModal, setIsShortageModal] = useState(false);
  const [aiType, setAiType] = useState(AiTypeList[1].type);
  const [inputDatas, setInputDatas] = useState([]);

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [target, setTarget] = useState("");
  const [objective, setObjective] = useState("");
  const [tone, setTone] = useState("");
  const [language, setLanguage] = useState("KR"); //언어 선택은 필수로 넣어야 하더라

  const [firstVariable, setFirstVariable] = useState(""); // 여행지
  const [secondVariable, setSecondVariable] = useState(""); // 여행 잘짜 혹은 일정
  const [thirdVariable, setThirdVariable] = useState(""); // 여행 주제
  const [fourthVariable, setFourthVariable] = useState("");
  const [fifthVariable, setFifthVariable] = useState("");

  const [imageRequestDescription, setImageRequestDescription] = useState("");
  // 자동생성
  const [autoOptions, setAutoOptions] = useState({
    seq: "",
    name: "",
    address: "",
    introduction: "",
    operatingHours: "",
    facilities: "",
    keywords1: "",
    keywords2: "",
  });
  const handleOptions = (updateData) => {
    setAutoOptions((prev) => ({
      ...prev,
      ...updateData,
    }));
  };
  const handleInitAutoOptions = () => {
    setAutoOptions({
      seq: "",
      name: "",
      address: "",
      introduction: "",
      operatingHours: "",
      facilities: "",
      keywords1: "",
      keywords2: "",
    });
  };
  // 추가설정
  const [addSetting, setAddSeting] = useState(false);
  // 필수 입력 progress
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const values = [
      autoOptions?.name || "",
      autoOptions?.address || "",
      autoOptions?.introduction || "",
      autoOptions?.operatingHours || "",
      autoOptions?.facilities || "" || "",
      autoOptions?.keywords1,
      autoOptions?.keywords2,
      firstVariable,
      secondVariable,
      thirdVariable,
      tone,
    ];
    const trueCount = (values && values?.filter(Boolean)?.length) || 0;
    const result = trueCount * 9.5 >= 100 ? 100 : trueCount * 9.5;
    setProgress(result);
  }, [firstVariable, secondVariable, thirdVariable, tone, autoOptions]);
  // 요청보내기
  const postTemplateCreate = useRequestPost();
  const postContentCreate = useRequestPost();
  const postImageCreate = useRequestPost();

  const handleSubmit = () => {
    const needPlum = 3;
    if (needPlum > getUserInfo?.data?.data?.remainingPlum) {
      setIsShortageModal(true);
      return;
    }
    if (progress === 100) {
      const data = {
        model: aiType,
        templateCode: templateCode.hospitalBlog[0].template_code,

        ...(brand && { brandSeq: brand }),
        ...(target && { targetSeq: target }),
        ...(tone && { toneCode: tone }),
        ...(language && { countryCode: language }),
        ...(objective && { objectiveCode: objective }),
        ...(autoOptions?.seq && { placeSeq: autoOptions?.seq }),

        ...(firstVariable && { firstVariable: firstVariable }),
        ...(secondVariable && { secondVariable: secondVariable }),
        ...(thirdVariable && { thirdVariable: thirdVariable }),
        ...(fourthVariable && { fourthVariable: fourthVariable }),
        ...(fifthVariable && { fifthVariable: fifthVariable }),
      };

      setInputDatas(data);
      postTemplateCreate.mutate({
        // 템플릿 생성 요청
        requestUrl: "/api/chat-completion/run",
        postData: data,
      });
      postContentCreate.mutate({
        // 콘텐츠 항목 생성 요청
        requestUrl: `/api/project/${projectSeq}/content`,
        postData: data,
      });
    }
  };
  // 요청 보내기 update 22
  const updatePlace = useApiMutation(
    PLACE.placeSeq(autoOptions?.seq),
    "patch",
    {
      onSuccess: (data) => {
        // console.log('내장소 업데이트 완료');
        handleSubmit();
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "내장소 업데이트에 실패했습니다.";
        alert(errorMessage);
      },
    }
  );
  const handleUpdatePlace = () => {
    if (!updatePlace?.isPending) {
      updatePlace?.mutate(autoOptions);
    }
  };

  // 요청 완료 컨트롤러
  useEffect(() => {
    if (imageRequestDescription) {
      if (
        postTemplateCreate.status === "success" &&
        postContentCreate.status === "success" &&
        postImageCreate.status === "success"
      ) {
        const { value } = postTemplateCreate?.data?.data;
        const { seq } = postContentCreate?.data?.data;
        // 이미지 생성하기도 필요
        const { imageS3Url } = postImageCreate?.data?.data;

        navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
          state: {
            post: value,
            contentSeq: seq,
            projectSeq: projectSeq,
            inputDatas: inputDatas,
            imageData: imageS3Url,
          },
        });
      } else {
        return;
      }
    } else if (!imageRequestDescription) {
      if (
        postTemplateCreate.status === "success" &&
        postContentCreate.status === "success"
      ) {
        const { value } = postTemplateCreate?.data?.data;
        const { seq } = postContentCreate?.data?.data;
        // 이미지 생성하기도 필요
        //   const { imageS3Url } = postImageCreate?.data;
        navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
          state: {
            post: value,
            contentSeq: seq,
            projectSeq: projectSeq,
            inputDatas: inputDatas,
            imageData: "",
          },
        });
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postTemplateCreate.status,
    postContentCreate.status,
    postImageCreate.status,
  ]);
  return (
    <div className="mt-10">
      {(postTemplateCreate.isPending ||
        postContentCreate.isPending ||
        postImageCreate.isPending) && <TimeLoadingModal second={30} />}
      <ShortageByteModal
        remainingPlum={getUserInfo?.data?.data?.remainingPlum}
        isShow={isShortageModal}
        setIsShow={setIsShortageModal}
      />
      <div>
        <AITypeDropdown
          selectValue={aiType}
          setSelectValue={setAiType}
          itemList={AiTypeList}
        />
      </div>
      <div className="mb-4 font-bold text-center">{`${templateInfo?.templateKR}, ${templateInfo?.info}`}</div>
      {/* 기본 input*/}
      <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
        <div>콘텐츠의 정확성 일치율</div>
        <div className="ml-2 font-bold text-main-pink">{progress}%</div>
      </div>
      <div className="mt-2 rounded-full bg-gray-lGray">
        <div
          className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
          style={{ width: `${progress}%`, transition: "width 0.1s linear" }}
        ></div>
      </div>
      {/* 자동생성 컴포넌트 */}
      <div>
        <div className="flex justify-between py-[10px] mt-[14px]">
          <div className="text-[18px] font-bold text-back-dGray">
            병원 정보 자동 입력
          </div>
          <div className="flex gap-[10px]">
            {/* <span className="px-[15px] py-[10px]">이저성한의원</span> */}
            <PlaceDropdown
              curSeq={autoOptions?.seq}
              handleOptions={handleOptions}
              // selectValue = '',
              // setSelectValue,
              handleInitAutoOptions={handleInitAutoOptions}
            />
            <SearchMapModal
              title="병원 정보 세팅하기"
              btn={
                <span className="px-[15px] py-[10px] rounded-[8px] bg-main-red text-white">
                  3초면 세팅 완료
                </span>
              }
              isShow={isSearchMapModal}
              setIsShow={setIsSearchMapModal}
              handleOptions={handleOptions}
            />
          </div>
        </div>
        <div className="rounded-[10px] border border-main-red p-[20px]">
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                병원명
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.name}
                onChange={(e) => handleOptions({ name: e.target.value })}
                placeholder="자동 생성"
              />
            </div>

            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                진료 과목
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.keywords1}
                onChange={(e) => handleOptions({ keywords1: e.target.value })}
                placeholder="자동 생성"
              />
            </div>

            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                병원 주소
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.address}
                onChange={(e) => handleOptions({ address: e.target.value })}
                placeholder="자동생성"
              />
            </div>
            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                대표 키워드
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.keywords2}
                onChange={(e) => handleOptions({ keywords2: e.target.value })}
                placeholder="자동생성"
              />
            </div>
            <div className="col-span-2">
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                병원 소개
              </div>
              <textarea
                type="text"
                className="w-full p-2 resize-none rounded-xl focus:outline-none px-[16px] py-[12px] h-[146px]"
                value={autoOptions?.introduction}
                onChange={(e) =>
                  handleOptions({ introduction: e.target.value })
                }
                placeholder="자동생성"
              ></textarea>
            </div>
            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                운영 시간
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.operatingHours}
                onChange={(e) =>
                  handleOptions({ operatingHours: e.target.value })
                }
                placeholder="자동생성"
              />
            </div>
            <div>
              <div className="font-semibold">
                <span className="m-1 text-main-pink"> *</span>
                편의 시설 및 서비스
              </div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none"
                value={autoOptions?.facilities || ""}
                onChange={(e) => handleOptions({ facilities: e.target.value })}
                placeholder="자동생성"
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
      {/* 변수 input*/}
      <div className="pt-8">
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <div className="font-semibold">
              <span className="m-1 text-main-pink"> *</span>
              주제
            </div>
            <input
              type="text"
              className="w-full p-2 rounded-xl focus:outline-none"
              onChange={(e) => setFirstVariable(e.target.value)}
              placeholder="수능 수험색 라식 할인 이벤트"
            />
          </div>

          <div>
            <div className="flex justify-between">
              <div className="font-semibold">
                <span className="m-1 text-main-pink">*</span>
                제목
              </div>
              <RecommandPlaceTitleModal
                model={aiType}
                // brandSeq={brand}
                // targetSeq={target}
                toneCode={tone}
                countryCode={language}
                // objectiveCode={objective}
                firstVariable={firstVariable}
                secondVariable={secondVariable}
                thirdVariable={thirdVariable}
                autoOptions={autoOptions}
                // fourthVariable={fourthVariable}
                setThirdVariable={setThirdVariable}
                // categoryNum={category.slice(1)}
                modalBtn={
                  <button className="text-xs text-main-pink">생성하기</button>
                }
              />
            </div>
            <input
              type="text"
              className="w-full p-2 rounded-xl focus:outline-none"
              value={thirdVariable}
              onChange={(e) => setThirdVariable(e.target.value)}
              placeholder="제목 입력"
            />
          </div>

          <div>
            <div className="font-semibold">
              <span className="m-1 text-main-pink"> *</span>
              톤앤매너
            </div>
            <TemplateDropdown
              // backgroundStyle={'#F8F9FA'}
              selectValue={tone}
              setSelectValue={setTone}
              itemList={toneCode.all}
              mainCode={"tone_code"}
              viewValueCode={"tone_name_kor"}
            />
          </div>

          <div>
            <div className="font-semibold">
              <span className="m-1 text-main-pink"> *</span>꼭 포함되어야 할
              내용
            </div>
            <input
              type="text"
              className="w-full p-2 rounded-xl focus:outline-none"
              onChange={(e) => setSecondVariable(e.target.value)}
              placeholder="수험표 지참한 모든 수험생분들께 시력교정술 특별 할..."
            />
          </div>
        </div>
      </div>
      {/* 추가 설정 */}
      <div className="flex justify-center w-full mt-8 text-sm">
        <button
          onClick={handleUpdatePlace}
          className={
            progress === 100
              ? "m-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]"
              : "m-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray"
          }
        >
          {`(-${template_plum}Byte) 생성하기`}
        </button>
      </div>
    </div>
  );
};
export default HospitalBlog;
