import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import BackConfirmModal from 'layout/NavigateHeader/BackConfirmModal';
const NavigateHeader = () => {
    const navigate = useNavigate();
    const locationURL = useLocation().pathname;
    const locationArr = locationURL.split('/').slice(1);
    const curLocation = locationArr[locationArr.length - 1]; // template 일 경우 모달 띄우기
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const navRecord = JSON.parse(localStorage.getItem('navRecord')); // templates, template, project, content
    const updateEditorLocation = () => localStorage.setItem('navArr', JSON.stringify(locationArr));
    useEffect(() => {
        updateEditorLocation();
    }, []);
    const handleBack = (bacIdx) => {
        navigate(Number(`-${locationArr.length - bacIdx - 1}`));
    };
    return (
        <div className="text-start w-full text-text-gray mt-[5px] flex items-center">
            {locationArr.map((el, idx) =>
                idx === locationArr.length - 1 ? (
                    <button
                        key={idx}
                        type="button"
                        className="font-bold text-xl rounded-lg text-text-black ml-[2px]"
                    >
                        {locationToKR(el)}
                    </button>
                ) : (
                    <div
                        key={idx}
                        className="flex items-center"
                    >
                        <button
                            onClick={() => (curLocation === 'template' ? setIsConfirmModal(true) : handleBack(idx))}
                            className="p-1 text-base rounded-lg hover:bg-gray-lGray"
                        >
                            <span>
                                {navRecord[el]
                                    ? navRecord[el]
                                    : !navRecord[el] && el === 'content'
                                    ? '콘텐츠'
                                    : !navRecord[el] && el === 'project'
                                    ? '프로젝트'
                                    : !navRecord[el] && el === 'templates'
                                    ? '템플릿'
                                    : el}
                            </span>
                        </button>
                        <span className="px-[2px]">/</span>
                        {curLocation === 'template' && isConfirmModal && (
                            <BackConfirmModal
                                setIsConfirmModal={setIsConfirmModal}
                                bacIdx={idx}
                                backFunc={handleBack}
                            />
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default NavigateHeader;

export const locationToKR = (location) => {
    switch (location) {
        case 'templates':
            return '템플릿';
        case 'template':
            return '템플릿생성';
        case 'project':
            return '프로젝트';
        case 'content':
            return '콘텐츠';
        default:
            return location;
    }
};
