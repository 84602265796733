// hooks
import { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useRequestGet, useRequestPatch } from 'hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
// Common Components

import BasicTooltip from 'components/ToolTips/BasicToolTips';

// icons
import { FaRegCopy } from 'react-icons/fa';
// utils
import { MarkdownToHtml } from 'utils/MarkdownToHtml';
const BehanceInital = () => {
    const ref = useRef();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');
    const contentSeq = searchParams.get('contentSeq');
    const [data, setData] = useState([]);
    const [isHasTitle, setIsHasTitle] = useState(false);
    // 초기 데이터 -> 객체화
    useEffect(() => {
        const newData = [];
        const imageRecommendationPattern = /<이미지 추천:\s*(.*?)>/;
        if (
            201 <= Number(state?.inputDatas?.templateCode?.slice(1)) &&
            Number(state?.inputDatas?.templateCode?.slice(1)) <= 542
        )
            setIsHasTitle(true);
        if (state?.imageData) {
            newData.push({
                type: 'image',
                contents: state?.imageData,
            });
        }
        if (state?.post) {
            const initialData = state.post.split('\n\n');
            initialData.forEach((el) => {
                const match = el.match(imageRecommendationPattern);
                if (match) {
                    newData.push({
                        type: 'recommand',
                        contents: match[1], // 이미지 추천 뒤의 텍스트
                    });
                } else {
                    newData.push({
                        type: 'text',
                        contents: el,
                    });
                }
            });
            if (isHasTitle) {
                handleMarkdown(newData.slice(1));
            } else handleMarkdown(newData);
        } else if (!state.post && state?.imageData) {
            handleMarkdown(newData);
        }
    }, []);
    // 객체화된 내부 데이터 -> HTML
    const handleMarkdown = (markdown) => {
        if (markdown?.length > 0) {
            const convertedHtmlData = [];
            markdown.forEach((item) => {
                if (item.type === 'text') {
                    convertedHtmlData.push({
                        ...item,
                        contents: MarkdownToHtml(item.contents),
                    });
                } else {
                    convertedHtmlData.push(item);
                }
            });
            // 처음 data state 저장
            setData(convertedHtmlData);
        }
    };

    // 자동 저장 요청을 보내는 함수
    const patchSave = useRequestPatch();
    useEffect(() => {
        if (data?.length > 0) {
            const patchBody = {
                contentHtml: JSON.stringify(data),
                ...state?.inputDatas,
                contentSeq: state?.contentSeq,
                projectSeq: state?.projectSeq,
            };
            patchSave.mutate({
                requestUrl: `/api/project/${projectSeq}/content/${contentSeq}`,
                patchData: patchBody,
            });
        }
    }, [data]);

    // 자동 저장이 완료된 후, 페이지 이동
    useEffect(() => {
        if (patchSave.status === 'success') {
            // console.log('데이터 생성, 저장 요청: ', patchSave.status);
            navigate(`/behance-editor?projectSeq=${projectSeq}&contentSeq=${contentSeq}`);
        }
    }, [patchSave.status]);
    return (
        <div className="wrapper">
            <div className="px-4 cherry-wrapper">
                <div className="h-full p-8 mt-4 mb-12 bg-white rounded-t-xl">
                    <div className="flex items-center justify-between w-full">
                        <div>
                            <div className="flex items-center">
                                <BasicTooltip
                                    title="Copy"
                                    placement="top"
                                    contents={<FaRegCopy className="w-4 h-4 mr-2 text-gray-dGray" />}
                                />
                                {/* 복사하기 옆에 제목을 추가하는 경우가 되겠다. */}
                                {/* {isHasTitle && fourthVariable && (
                                    <div className="text-lg font-bold">{fourthVariable} </div>
                                )} */}
                            </div>
                        </div>
                        <div className="flex items-center select-none w-[210px] ml-2">
                            <div className="text-xs text-gray-dGray">자동 저장 완료 15:00</div>
                            <button className="px-3 py-1 ml-2 text-sm border rounded-[10px] border-gray-gray">
                                임시 저장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BehanceInital;
