import { useEffect, useState } from 'react';
import { useRequestPost } from 'hooks/useRequest';
// icons
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
// common
import Loading from 'components/Loading/Loading';
const RecommandTitleModal = ({
    model,
    brandSeq,
    targetSeq,
    toneCode,
    countryCode,
    objectiveCode,
    firstVariable,
    secondVariable,
    thirdVariable,
    fourthVariable, //게시글 제목
    setFourthVariable,

    categoryNum,
    modalBtn,
}) => {
    const [isShow, setIsShow] = useState(false);
    const [createdTitle, setCreatedTitle] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(null);
    const postTitle = useRequestPost();
    const handleSubmit = () => {
        if (
            !categoryNum ||
            //   !brandSeq ||
            //   !targetSeq ||
            //   !toneCode ||
            //   !countryCode ||
            !objectiveCode ||
            !firstVariable ||
            !secondVariable ||
            !thirdVariable
        ) {
            alert('필수 값을 먼저 입력해주세요');
            return;
        }
        const titleTemplateCode = `C${Number(categoryNum - 100)}`;
        const data = {
            model: model,
            templateCode: titleTemplateCode,
            ...(brandSeq && { brandSeq: brandSeq }),
            ...(targetSeq && { targetSeq: targetSeq }),
            ...(toneCode && { toneCode: toneCode }),
            ...(countryCode && { countryCode: countryCode }),
            ...(objectiveCode && { objectiveCode: objectiveCode }),
            ...(firstVariable && { firstVariable: firstVariable }),
            ...(secondVariable && { secondVariable: secondVariable }),
            ...(thirdVariable && { thirdVariable: thirdVariable.join(',') }),
            ...(fourthVariable && { fourthVariable: fourthVariable }),
        };
        if (postTitle?.isPending) return;
        postTitle.mutate({
            // 제목 생성 요청
            requestUrl: '/api/chat-completion/run',
            postData: data,
        });
    };
    useEffect(() => {
        if (postTitle.status === 'success') {
            const value = postTitle?.data?.data?.value ?? ''; // value가 undefined일 때 기본값 설정
            if (value) {
                // 모든 줄바꿈(\n)을 기준으로 분리 후, 연속된 줄바꿈을 하나로 병합
                const parsedTitle = value.split(/\n+/).filter(Boolean);
                setCreatedTitle(parsedTitle);
                setIsShow(true);
            } else {
                console.error('No value found in postTitle.data.data');
            }
        }
    }, [postTitle.status]);
    return (
        <div>
            {postTitle?.isPending && <Loading />}
            <div onClick={handleSubmit}>{modalBtn}</div>
            {isShow && createdTitle?.length > 0 && (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="fixed w-screen h-screen bg-[#00000050] left-0 top-0 z-40 flex justify-center items-center select-none"
                >
                    <div className="p-4 rounded-lg bg-gradient-modal">
                        <div className="flex justify-between p-2 text-sm font-bold">
                            <div>블로그 제목 생성</div>
                            <IoClose onClick={() => setIsShow(false)} />
                        </div>
                        <div className="p-1 rounded-lg bg-text-white max-w-[30rem] text-sm">
                            {createdTitle?.map((el, idx) => (
                                <div
                                    key={idx}
                                    className={
                                        idx === selectedIdx
                                            ? 'py-2 px-3 m-2 rounded-md bg-gray-sGray  flex justify-between items-center'
                                            : 'py-2 px-3 m-2 rounded-md flex justify-between text-gray-dGray'
                                    }
                                    onClick={() => setSelectedIdx(idx)}
                                >
                                    {el?.slice(3)}
                                    {idx === selectedIdx ? (
                                        <FaCheck className="w-8 text-main-pink" />
                                    ) : (
                                        <div className="w-8"></div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center mt-2 text-sm">
                            <div
                                className="px-2 py-1 mr-1 border rounded-md border-gray-gray text-gray-dGray"
                                onClick={handleSubmit}
                            >
                                <MdRefresh />
                            </div>

                            <button
                                onClick={() => {
                                    setFourthVariable(createdTitle[selectedIdx]?.slice(3));
                                    setIsShow(false);
                                }}
                                className="px-16 py-1 rounded-md bg-main-red text-text-white"
                            >
                                사용
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecommandTitleModal;
