import AddCard from "components/Card/AddCard";
import ContentsCard from "components/Card/ContentsCard";
import React, { useState, useEffect, useCallback } from "react";
import {
  useRequestGet,
  useRequestDelete,
  useRequestPost,
} from "hooks/useRequest";
import { FiFolderPlus, FiTrash2 } from "react-icons/fi";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getGenderText } from "utils/GenderUtils";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";

//튜토리얼 추가
import { useNavigate } from "react-router";
import CustomModal from "components/Modals/CustomModal";
import tutorial_target from "assets/tutorial/setTutorial_target.png";
const TargetSettings = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [contentData, setContentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const targetContent = useRequestGet(`api/target?page=${page}&size=19`, [
    "targetContent",
    page,
  ]);

  const fetchData = useCallback(() => {
    if (targetContent.status === "success") {
      setContentData(targetContent.data.data.data);
      setTotalPages(targetContent.data.data.pageInfo.totalPages);
      setIsLoading(false); // 데이터 로드 완료
    } else if (targetContent.status === "error") {
      setIsLoading(false); // 데이터 로드 완료
    }
  }, [targetContent]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [targetContent.status, fetchData]);

  useEffect(() => {
    if (location.state?.newTargetAdded) {
      queryClient.invalidateQueries("targetContent");
    }
  }, [location.state, queryClient]);

  const deleteTargetMutation = useRequestDelete("targetContent");

  const handleDelete = (id) => {
    deleteTargetMutation.mutate(
      { url: `/api/target/${id}` },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("targetContent").then(() => {
            fetchData();
            toast.success("타겟이 성공적으로 삭제되었습니다!");
          });
        },
      }
    );
  };

  const targetClone = useRequestPost();

  const handleClone = (id) => {
    targetClone.mutate(
      {
        requestUrl: `/api/target/clone/${id}`,
        data: id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("targetContent");
          toast.success("타겟이 성공적으로 복제되었습니다!");
        },
      }
    );
  };

  const itemList = [
    {
      label: "타겟 복제",
      icon: (
        <FiFolderPlus className="w-4 h-4 text-gray-gray ml-2 group-hover:text-main-red" />
      ),
      selectedIcon: <FiFolderPlus className="w-4 h-4 text-main-red ml-2" />,
      onClick: (id) => handleClone(id),
    },
    {
      label: "타겟 삭제",
      icon: (
        <FiTrash2 className="w-4 h-4 text-gray-gray ml-2 group-hover:text-main-red" />
      ),
      selectedIcon: <FiTrash2 className="w-4 h-4 text-main-red ml-2" />,
      onClick: (id) => handleDelete(id),
    },
  ];
  //튜토리얼
  const [showTutorial, setShowTutorial] = useState(false);
  useEffect(() => {
    const targetClickedSetting = localStorage.getItem("targetClickedSetting");

    if (!targetClickedSetting) {
      setShowTutorial(true); // 클릭한 적 없으면 모달 띄우기
    }
  }, []);

  const handleTutorialClose = () => {
    localStorage.setItem("targetClickedSetting", "true"); // 버튼 클릭 기록 저장
    setShowTutorial(false);
    navigate("/setting/target");
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showTutorial && (
            <CustomModal
              isOpen={true}
              title="타겟 설정"
              content={
                <div>
                  <img
                    src={tutorial_target}
                    alt="셋팅 튜토리얼"
                    className="w-[400px] mt-2"
                  />
                  <div className="text-center bg-text-white p-2 rounded-lg mt-2 text-sm/6">
                    <h4 className="font-semibold">
                      <span className="text-main-red">02.</span> 브랜드의 타겟
                      설정
                    </h4>
                    <p>
                      운영하시는 브랜드의 타겟까지 미리 설정해주시면
                      <br />
                      콘텐츠 제작시 타겟에 맞는 결과물을 생성해드립니다.
                    </p>
                  </div>
                </div>
              }
              activeBtnText="설정하기"
              activeFunc={handleTutorialClose}
              backgroundStyle="gradient"
              closeOnClickOutside={true}
              showCloseButton={true}
            />
          )}
          <div className="mt-4 flex flex-wrap gap-4">
            <AddCard name={"타겟"} targetPath={"/setting/target"} />
            {contentData.map((el, idx) => (
              <ContentsCard
                key={idx}
                date={el.modifiedAt}
                title={el.targetName}
                age={el.targetAgeGroup}
                gender={getGenderText(el.targetGender)}
                interest={el.targetInterestsNameKor}
                btnTit={"수정하기"}
                itemList={itemList}
                id={el.seq}
                type={"target"}
                onDelete={handleDelete}
              />
            ))}
          </div>
          <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        </>
      )}
    </div>
  );
};

export default TargetSettings;
