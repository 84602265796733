import instagram from 'assets/template/icons/instagram.png';
import naverBlog from 'assets/template/icons/naverBlog.png';
import hospitalBlog from 'assets/template/icons/hospitalBlog.png';
import tStory from 'assets/template/icons/tStory.png';
import linkedinProfile from 'assets/template/icons/linkedinProfile.png';
import ImageStudio from 'assets/template/icons/ImageStudio.png';
import diary from 'assets/template/icons/diary.png';
import dreamDiary from 'assets/template/icons/dreamDiary.png';
import reflectionLetter from 'assets/template/icons/reflectionLetter.png';
import dreamInterpretation from 'assets/template/icons/dreamInterpretation.png';
import toast from 'assets/template/icons/toast.png';
import interviewQeustionsAnswers from 'assets/template/icons/interviewQeustionsAnswers.png';
import copyWritingIdea from 'assets/template/icons/copyWritingIdea.png';
import improvedAIDAModelAD from 'assets/template/icons/improvedAIDAModelAD.png';
import productDetail from 'assets/template/icons/productDetail.png';
import googleAD from 'assets/template/icons/googleAD.png';
import marketingPlanDraft from 'assets/template/icons/marketingPlanDraft.png';
import expressingMoodWithColor from 'assets/template/icons/expressingMoodWithColor.png';
import businessPlanDraft from 'assets/template/icons/businessPlanDraft.png';
import appPushMessage from 'assets/template/icons/appPushMessage.png';
import pressRelease from 'assets/template/icons/pressRelease.png';
import rewriteArticle from 'assets/template/icons/rewriteArticle.png';
import notice from 'assets/template/icons/notice.png';
import travelSchedule from 'assets/template/icons/travelSchedule.png';

export const templateIconMatcher = {
    instagram: instagram,
    naverBlog: naverBlog,
    tStory: tStory,
    linkedinProfile: linkedinProfile,
    ImageStudio: ImageStudio,
    diary: diary,
    dreamDiary: dreamDiary,
    reflectionLetter: reflectionLetter,
    dreamInterpretation: dreamInterpretation,
    toast: toast,
    interviewQeustionsAnswers: interviewQeustionsAnswers,
    copyWritingIdea: copyWritingIdea,
    improvedAIDAModelAD: improvedAIDAModelAD,
    productDetail: productDetail,
    googleAD: googleAD,
    marketingPlanDraft: marketingPlanDraft,
    expressingMoodWithColor: expressingMoodWithColor,
    businessPlanDraft: businessPlanDraft,
    appPushMessage: appPushMessage,
    pressRelease: pressRelease,
    rewriteArticle: rewriteArticle,
    notice: notice,
    travelSchedule: travelSchedule,
    hospitalBlog: hospitalBlog,
};
