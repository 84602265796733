// hooks
import { useState, useEffect } from 'react';
import { useRequestGet, useRequestPost, useRequestPatch } from 'hooks/useRequest';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
// commons
import TemplateDropdown from 'components/Dropdowns/TemplateDropdown';
import KeywordTagInputBottom from 'components/Inputs/KeywordTagInputBottom';
import TextArea from 'components/TextArea';
import AITypeDropdown from 'components/Dropdowns/AITypeDropdown';
import RecommandKeywordModal from 'pages/Template/components/Modals/RecommandKeywordModal';
import TimeLoadingModal from 'pages/BehanceEditor/components/Modals/TimeLoadingModal';
import ShortageByteModal from 'components/Modals/ShortageByteModal';
import SearchMapModal from 'components/Modals/SearchMapModal';
import PlaceDropdown from 'components/Dropdowns/PlaceDropdown';
// utils
import { objectiveCode, templateCode, toneCode, languageCode, templateQuestions } from 'utils/TemplateQuestions';
import { ReCreateContentsChanger } from 'utils/ReCreateContentsChanger';
import { PLACE } from 'utils/consts/apiEndpoint';
// icons
import { FiFilePlus } from 'react-icons/fi';
//images
import plum_byte_icon_logo from 'assets/logo/plum_byte_icon_logo.png';
import plum_byte_logo from 'assets/logo/plum_byte_icon_logo.png';
import loadingImg from 'assets/images/loadingImg.gif';
import RecommandPlaceTitleModal from 'pages/Template/components/Modals/RecommandPlaceTitleModal';
// redux
import { useDispatch } from 'react-redux';
import { setIsRefetchPlum } from '../../../../../redux/isRefetchPlum';
const HospitalBlog = ({
    refetchFunc,
    initTemplateCode,
    initFirstVariable,
    initSecondVariable,
    initThirdVariable,
    initFourthVariable,
    initFifthVariable,
    initBrand,
    initTarget,
    initTone,
    initLanguage,
    initObjective,
    initModel,
}) => {
    const dispatch = useDispatch();
    // 문제점: 현재 정보들로 업데이트 요청 보내는 것..
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');
    const contentSeq = searchParams.get('contentSeq');
    const getUserInfo = useRequestGet('/api/user', 'userInfo');

    const getBrand = useRequestGet('/api/brand', 'brand'); //getBrand.data.data.data
    const getTarget = useRequestGet('/api/target', 'target'); //getBrand.data.data.data
    const AiTypeList = [
        { type: 'gpt', title: 'GPT', describe: '창의적인 텍스트 생성' },
        { type: 'claude', title: 'Claude', describe: '자연스러운 텍스트 생성' },
    ];
    const [isShortageModal, setIsShortageModal] = useState(false);
    const [aiType, setAiType] = useState(initModel);

    const [category, setCategory] = useState(initTemplateCode);
    const [brand, setBrand] = useState(initBrand);
    const [target, setTarget] = useState(initTarget);
    const [objective, setObjective] = useState(initObjective);
    const [tone, setTone] = useState(initTone);
    const [language, setLanguage] = useState(initLanguage); //언어 선택은 필수로 넣어야 하더라
    const [firstVariable, setFirstVariable] = useState(initFirstVariable);
    const [secondVariable, setSecondVariable] = useState(initSecondVariable);
    const [thirdVariable, setThirdVariable] = useState(initThirdVariable);
    const [fourthVariable, setFourthVariable] = useState(initFourthVariable);
    const [fifthVariable, setFifthVariable] = useState(initFifthVariable);
    const [imageRequestDescription, setImageRequestDescription] = useState('');
    // 추가설정
    const [progress, setProgress] = useState(0);
    const [isSearchMapModal, setIsSearchMapModal] = useState(false);

    // 자동생성
    const [autoOptions, setAutoOptions] = useState({
        seq: '',
        name: '',
        address: '',
        introduction: '',
        operatingHours: '',
        facilities: '',
        keywords1: '',
        keywords2: '',
    });

    useEffect(() => {
        const values = [
            autoOptions?.name,
            autoOptions?.address,
            autoOptions?.introduction,
            autoOptions?.operatingHours,
            autoOptions?.facilities,
            autoOptions?.keywords1,
            autoOptions?.keywords2,
            firstVariable,
            secondVariable,
            thirdVariable,
            tone,
        ];
        const trueCount = (values && values?.filter(Boolean)?.length) || 0;
        const result = trueCount * 9.5 >= 100 ? 100 : trueCount * 9.5;
        setProgress(result);
    }, [firstVariable, secondVariable, thirdVariable, tone, autoOptions]);

    const handleOptions = (updateData) => {
        setAutoOptions((prev) => ({
            ...prev,
            ...updateData,
        }));
    };

    const handleInitAutoOptions = () => {
        setAutoOptions({
            seq: '',
            name: '',
            address: '',
            introduction: '',
            operatingHours: '',
            facilities: '',
            keywords1: '',
            keywords2: '',
        });
    };

    // 요청보내기
    const postTemplateCreate = useRequestPost();
    const postImageCreate = useRequestPost();

    const handleSubmit = () => {
        const needPlum = imageRequestDescription ? 4 : 3;
        if (needPlum > getUserInfo?.data?.data?.remainingPlum) {
            setIsShortageModal(true);
            return;
        }
        if (progress === 100) {
            const data = {
                model: aiType,
                templateCode: category,

                ...(brand && { brandSeq: brand }),
                ...(target && { targetSeq: target }),
                ...(tone && { toneCode: tone }),
                ...(language && { countryCode: language }),
                ...(objective && { objectiveCode: objective }),

                ...(firstVariable && { firstVariable: firstVariable }),
                ...(secondVariable && { secondVariable: secondVariable }),
                ...(thirdVariable && { thirdVariable: thirdVariable }),
                ...(fourthVariable && { fourthVariable: fourthVariable }),
                ...(fifthVariable && { fifthVariable: fifthVariable }),
            };
            // 템플릿&이미지 생성 요청
            postTemplateCreate.mutate({
                requestUrl: '/api/chat-completion/run',
                postData: data,
            });
            const imageData = {
                templateCode: 'C04',
                prompt: imageRequestDescription,
                imageRatio: '16:9',
            };
            if (imageRequestDescription) {
                postImageCreate.mutate({
                    requestUrl: `/api/project/${projectSeq}/content/${contentSeq}/images/create/v2`,
                    postData: imageData,
                });
            }
        }
    };
    // 요청 보내기 update 22
    const updatePlace = useApiMutation(PLACE.placeSeq(autoOptions?.seq), 'patch', {
        onSuccess: (data) => {
            console.log('내장소 업데이트 완료');
            handleSubmit();
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '내장소 업데이트에 실패했습니다.';
        },
    });
    const handleUpdatePlace = () => {
        if (!updatePlace?.isPending) {
            updatePlace?.mutate(autoOptions);
        }
    };
    // 요청 완료 컨트롤러
    useEffect(() => {
        if (
            imageRequestDescription &&
            postTemplateCreate.status === 'success' &&
            postImageCreate.status === 'success'
        ) {
            // 이미지 생성하기도 필요
            const { value } = postTemplateCreate?.data?.data;
            const { imageS3Url } = postImageCreate?.data?.data;
            const contentHtml = ReCreateContentsChanger(category, value, imageS3Url);
            handleAutoSave(contentHtml, imageS3Url);
            dispatch(setIsRefetchPlum(true));
        } else if (!imageRequestDescription && postTemplateCreate.status === 'success') {
            const { value } = postTemplateCreate?.data?.data;
            const contentHtml = ReCreateContentsChanger(category, value);
            handleAutoSave(contentHtml);
            dispatch(setIsRefetchPlum(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postTemplateCreate.status, postImageCreate.status]);

    //   저장 요청
    const patchSave = useRequestPatch();
    const handleAutoSave = (contentHtml, imageS3Url) => {
        // 자동 저장 요청을 보내는 함수
        const patchBody = {
            contentHtml: JSON.stringify(contentHtml),
            projectSeq: projectSeq,
            contentSeq: contentSeq,

            ...(imageS3Url && { s3Urls: [imageS3Url] }),
            templateCode: category,
            ...(aiType && { model: aiType }),
            ...(firstVariable && { firstVariable: firstVariable }),
            ...(secondVariable && { secondVariable: secondVariable }),
            ...(thirdVariable && { thirdVariable: thirdVariable }),
            ...(fourthVariable && { fourthVariable: fourthVariable }),
            ...(fifthVariable && { fifthVariable: fifthVariable }),

            ...(autoOptions?.seq && { placeSeq: autoOptions?.seq }),
            ...(brand && { brandSeq: brand }),
            ...(target && { targetSeq: target }),
            ...(tone && { toneCode: tone }),
            ...(language && { countryCode: language }),
            ...(objective && { objectiveCode: objective || 'KR' }),
        };
        patchSave.mutate({
            requestUrl: `/api/project/${projectSeq}/content/${contentSeq}`,
            patchData: patchBody,
        });
    };
    useEffect(() => {
        if (patchSave.status === 'success') {
            refetchFunc();
        }
    }, [patchSave.status]);
    return (
        <div className="overflow-y-scroll pb-14 p-3 max-h-[35rem]">
            {(postTemplateCreate.isPending || postImageCreate.isPending) && <TimeLoadingModal second={35} />}
            <ShortageByteModal
                remainingPlum={getUserInfo?.data?.data?.remainingPlum}
                isShow={isShortageModal}
                setIsShow={setIsShortageModal}
            />
            <div>
                <div className="font-semibold">
                    <span className="text-main-pink"> *</span>
                    적용 모델
                </div>
                <AITypeDropdown
                    widthPx={'100%'}
                    selectValue={aiType}
                    setSelectValue={setAiType}
                    itemList={AiTypeList}
                />
            </div>
            {/* <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div> */}

            {/* 변수 input*/}
            <div>
                <div className="mt-3 flex flex-col gap-[10px]">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        병원 정보 자동입력
                    </div>
                    <PlaceDropdown
                        curSeq={autoOptions?.seq}
                        handleOptions={handleOptions}
                        // selectValue = '',
                        // setSelectValue,
                        handleInitAutoOptions={handleInitAutoOptions}
                    />
                    <SearchMapModal
                        title="병원 정보 세팅하기"
                        btn={
                            <div className="w-full py-[10px] rounded-[8px] bg-main-red text-white">3초면 세팅 완료</div>
                        }
                        isShow={isSearchMapModal}
                        setIsShow={setIsSearchMapModal}
                        handleOptions={handleOptions}
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        병원명
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.name}
                        onChange={(e) => handleOptions({ name: e.target.value })}
                        placeholder="자동 생성"
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        진료 과목
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.keywords1}
                        onChange={(e) => handleOptions({ keywords1: e.target.value })}
                        placeholder="자동 생성"
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        병원 주소
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.address}
                        onChange={(e) => handleOptions({ address: e.target.value })}
                        placeholder="자동생성"
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        대표 키워드
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.keywords2}
                        onChange={(e) => handleOptions({ keywords2: e.target.value })}
                        placeholder="자동생성"
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        병원 소개
                    </div>
                    <textarea
                        type="text"
                        className="w-full p-2 resize-none rounded-xl focus:outline-none px-[16px] py-[12px] h-[146px] bg-[#F8F9FA]"
                        value={autoOptions?.introduction}
                        onChange={(e) => handleOptions({ introduction: e.target.value })}
                        placeholder="자동생성"
                    ></textarea>
                </div>{' '}
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        운영 시간
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.operatingHours}
                        onChange={(e) => handleOptions({ operatingHours: e.target.value })}
                        placeholder="자동생성"
                    />
                </div>{' '}
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="m-1 text-main-pink"> *</span>
                        편의 시설 및 서비스
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={autoOptions?.facilities}
                        onChange={(e) => handleOptions({ facilities: e.target.value })}
                        placeholder="자동생성"
                    />
                </div>
                <div className="mt-3">
                    <div className="font-semibold">
                        <span className="text-main-pink"> *</span>
                        주제
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={firstVariable}
                        onChange={(e) => setFirstVariable(e.target.value)}
                        placeholder="수능 수험색 라식 할인 이벤트"
                    />
                </div>
                <div className="mt-3">
                    <div className="flex items-center justify-between">
                        <div className="font-semibold">
                            <span className="text-main-pink"> *</span>
                            제목
                        </div>
                        <RecommandPlaceTitleModal
                            model={aiType}
                            // brandSeq={brand}
                            // targetSeq={target}
                            toneCode={tone}
                            countryCode={language}
                            // objectiveCode={objective}
                            firstVariable={firstVariable}
                            secondVariable={secondVariable}
                            thirdVariable={thirdVariable}
                            autoOptions={autoOptions}
                            // fourthVariable={fourthVariable}
                            // setFourthVariable={setFourthVariable}
                            // categoryNum={category.slice(1)}
                            modalBtn={<button className="text-xs text-main-pink">생성하기</button>}
                        />
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={thirdVariable}
                        onChange={(e) => setThirdVariable(e.target.value)}
                        placeholder="생성 또는 입력"
                    />
                </div>
                <div className="mt-3">
                    <div className="flex justify-between">
                        <div className="font-semibold">
                            <span className="text-main-pink"> *</span>꼭 포함되어야할 내용
                        </div>
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                        value={secondVariable}
                        onChange={(e) => setSecondVariable(e.target.value)}
                        placeholder="수험표 지참한 모든 수험생분들께 시력교정술 특별 할..."
                    />
                </div>
            </div>

            {/* 추가 설정 */}
            <div className="mt-3">
                <div>
                    <div className="font-semibold">톤앤매너</div>
                    <TemplateDropdown
                        backgroundStyle={'#F8F9FA'}
                        selectValue={tone}
                        setSelectValue={setTone}
                        itemList={toneCode.all}
                        mainCode={'tone_code'}
                        viewValueCode={'tone_name_kor'}
                    />
                </div>

                <div className="mt-3">
                    <div className="font-semibold">언어</div>
                    <TemplateDropdown
                        backgroundStyle={'#F8F9FA'}
                        selectValue={language}
                        setSelectValue={setLanguage}
                        itemList={languageCode}
                        mainCode={'language_code'}
                        viewValueCode={'language_name_kor'}
                    />
                </div>

                {/* <div className="mt-3">
                    <div className="flex justify-between font-semibold">
                        <div>이미지</div>
                        <div className="flex items-center text-xs text-main-purple">
                            여기서만
                            <div className=" ml-1 bg-[#9c1ad410] flex items-center p-1 rounded-md">
                                <img
                                    alt="plum_byte_icon_logo"
                                    src={plum_byte_icon_logo}
                                    className="w-4 h-4 "
                                />
                                1Byte
                            </div>
                        </div>
                    </div>
                    <TextArea
                        id="text"
                        placeholder="내용입력"
                        value={imageRequestDescription}
                        setValue={setImageRequestDescription}
                        rows={3}
                    />
                </div> */}
            </div>
            <div className="flex justify-center w-full mt-8 text-sm bg-white">
                <button
                    onClick={handleUpdatePlace}
                    className={`${
                        progress === 100 ? 'bg-gradient-to-tr from-[#9218D4] to-[#D22585]' : 'bg-gray-gray'
                    } pl-1 pr-10 m-3 justify-between rounded-lg text-white flex items-center fixed bottom-5`}
                >
                    <span className={`${progress === 100 ? 'bg-[#8217AA]' : 'bg-gray-dGray'} my-1 mr-6 p-2 rounded-lg`}>
                        <FiFilePlus className="w-6 h-6" />
                    </span>
                    <span>{imageRequestDescription ? '(-4Byte) 재생성하기' : '(-3Byte) 재생성하기'}</span>
                </button>
            </div>
        </div>
    );
};
export default HospitalBlog;
