import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IoIosArrowDown } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
export default function AITypeDropdown({ widthPx = '180px', selectValue = '', setSelectValue, itemList }) {
    const handleChange = (e) => {
        setSelectValue(e.target.value);
    };
    return (
        <Box sx={{}}>
            <FormControl sx={{ width: '100%' }}>
                <Select
                    sx={{
                        width: widthPx,
                        fontSize: '13px',
                        color: '#858e96 !important',
                        background: '#FFFFFF',
                        borderRadius: '10px',
                        '& .MuiSelect-select': {
                            padding: 1,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #858e9630',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #858e9630', // 포커스 시에도 기존 테두리 유지
                        },
                        '&.Mui-focused': {
                            outline: 'none', // 포커스 시 아웃라인 제거
                        },
                        '.MuiSelect-select>svg': {
                            display: 'none',
                        },
                    }}
                    labelId="demo-simple-select-label"
                    value={selectValue}
                    IconComponent={IoIosArrowDown}
                    onChange={(e) => handleChange(e)}
                    displayEmpty // 기본 텍스트 표시
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '15px',
                                // margin: '10px',
                            },
                        },
                    }} // 이 부분에서 MenuItem의 너비를 20px로 제한합니다.
                    renderValue={(selected) => {
                        // 선택된 항목의 title만 표시
                        if (!selected) {
                            return <em>Select an option</em>;
                        }
                        const selectedItem = itemList.find((el) => el.type === selected);
                        return selectedItem ? selectedItem.title : '';
                    }}
                >
                    {itemList.map((el, idx) => (
                        <MenuItem
                            key={idx}
                            value={el.type}
                            sx={
                                el === selectValue
                                    ? {
                                          fontSize: '13px',
                                          borderRadius: '10px',
                                          color: '#000000',
                                          background: '#F1F3F5 !important',
                                          marginY: '3px',
                                      }
                                    : {
                                          fontSize: '13px',
                                          borderRadius: '10px',
                                          color: '#858e96',
                                          background: '#ffffff !important',
                                          '&:hover': {
                                              background: '#F1F3F5 !important',
                                          },
                                          marginY: '3px',
                                      }
                            }
                        >
                            {el.type === selectValue ? (
                                <FaCircle className="text-main-pink border-2 p-[1px] rounded-full border-main-pink mr-4" />
                            ) : (
                                <FaCircle className="text-gray-gray border-2 p-[1px] rounded-full border-gray-gray mr-4" />
                            )}
                            <div>
                                <div>{el.title}</div>
                                <div style={{ fontSize: '11px', color: '#858e96' }}>{el.describe}</div>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
