// hooks
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRequestGet, useRequestPost } from "hooks/useRequest";
// common components
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import

import ProjectCard from "components/Card/ProjectCard";
import BasicModal from "components/Modals/BasicModal";
import NavigateHeader from "layout/NavigateHeader";
// icons
import { FiFilePlus } from "react-icons/fi";

//튜토리얼 추가
import { useNavigate } from "react-router";
import CustomModal from "components/Modals/CustomModal";
import tutorial_project from "assets/tutorial/setTutorial_project.png";

const Projects = () => {
  const [searchParams] = useSearchParams();
  const template = searchParams.get("template");

  const location = useLocation();
  const locationList = location.pathname.split("/");
  const [projects, setProjects] = useState([]);
  const [newProjectName, setNewProjectName] = useState("");
  const getProjects = useRequestGet("/api/project", "getProjects");
  const postNewProject = useRequestPost();
  const handleNewPost = async () => {
    const nameRegex = /^[가-힣a-zA-Z0-9!@#$%^&*()-_+=<>?\s]{2,20}$/;
    if (!nameRegex.test(newProjectName)) {
      toast.error("프로젝트 이름은 2자 이상 20자 이하로 구성됩니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (newProjectName) {
      try {
        await postNewProject.mutateAsync({
          requestUrl: "/api/project",
          postData: { projectName: newProjectName },
        });
        await getProjects.refetch();
        setNewProjectName("");

        // setProjectsChanged(true);
      } catch (error) {
        console.error("Error creating project:", error);
        toast.error("프로젝트를 생성하는 데 문제가 발생했습니다.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("프로젝트명을 입력하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProjectRefetch = () => {
    getProjects.refetch();
  };

  useEffect(() => {
    if (getProjects?.data) {
      setProjects(getProjects?.data?.data?.data);
    }
  }, [getProjects?.data]);
  //튜토리얼
  const navigate = useNavigate();
  const [showTutorial, setShowTutorial] = useState(false);
  useEffect(() => {
    const projectClickedSetting = localStorage.getItem("projectClickedSetting");

    if (!projectClickedSetting) {
      setShowTutorial(true); // 클릭한 적 없으면 모달 띄우기
    }
  }, []);

  const handleTutorialClose = () => {
    localStorage.setItem("projectClickedSetting", "true"); // 버튼 클릭 기록 저장
    setShowTutorial(false);
  };

  return (
    <div className="pb-10 wrapper">
      {showTutorial && (
        <CustomModal
          isOpen={true}
          title="프로젝트와 콘텐츠"
          content={
            <div>
              <img
                src={tutorial_project}
                alt="셋팅 튜토리얼"
                className="w-[400px] mt-2"
              />
              <div className="text-center bg-text-white p-2 rounded-lg mt-2 text-sm/6">
                <h4 className="font-semibold">프로젝트, 콘텐츠가 뭐에요?</h4>
                <p>
                  프로젝트는 대분류로{" "}
                  <span className="text-main-red font-semibold">
                    브랜드 기준으로
                  </span>{" "}
                  폴더를 만들면 되고,
                  <br />
                  콘텐츠는 소분류로 인스타그램, 블로그 등{" "}
                  <span className="text-main-red font-semibold">
                    콘텐츠를 중심으로
                  </span>
                  <br /> 폴더를 만들어주시면 편하게 분류해서 사용하실 수
                  있습니다.
                </p>
              </div>
            </div>
          }
          activeBtnText="설정하기"
          activeFunc={handleTutorialClose}
          backgroundStyle="gradient"
          closeOnClickOutside={true}
          showCloseButton={true}
        />
      )}
      <div className="flex flex-wrap justify-center cherry-wrapper ">
        <NavigateHeader />
        {projects && (
          <div className="flex flex-wrap w-full mt-3 gap-4 ">
            <div className="inline-block">
              <div className="flex flex-col justify-center w-[215px] h-[215px] border-dashed items-center border border-gray-gray text-sm  rounded-[18px]">
                <BasicModal
                  title="프로젝트 이름"
                  modalBtn={
                    <button className="text-gray-gray ">
                      <FiFilePlus className="w-6 h-6 mx-auto mt-3 mb-2 text-base" />
                      <div>프로젝트 추가</div>
                    </button>
                  }
                  content={
                    <div>
                      <input
                        value={newProjectName}
                        onChange={(e) => setNewProjectName(e.target.value)}
                        type="text"
                        maxLength={20}
                        className="w-[400px] h-[40px] border border-gray-gray text-text-dGray rounded-lg my-3 p-2"
                        placeholder="프로젝트 이름 입력"
                      />
                    </div>
                  }
                  activeBtnText={"완료"}
                  activeFunc={handleNewPost}
                  backgroundStyle={"gradient"}
                />
              </div>
            </div>
            {projects.map((el, idx) => (
              <div className=" relative" key={idx}>
                <ProjectCard
                  tab="project"
                  seq={el.seq}
                  title={el.projectName}
                  date={el.modifiedAt && el.modifiedAt}
                  btnTit={el.hasContents ? "입장하기" : "생성하기"}
                  hasContents={el.hasContents}
                  refetchFunc={handleProjectRefetch}
                  navigateUrl={
                    locationList[1] === "templates"
                      ? `/templates/project/content?projectSeq=${el.seq}&&template=${template}`
                      : `/project/content?projectSeq=${el.seq}`
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
