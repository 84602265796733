import { useState } from "react";
import { IoClose } from "react-icons/io5";

const CustomModal = ({
  title = "타이틀을 입력하세요",
  content = <input type="text" />,
  modalBtn,
  activeBtnText = "확인",
  activeFunc,
  backgroundStyle = "white",
  isOpen = false,
  closeOnClickOutside = true, // 바깥 클릭 시 닫기 여부
  showCloseButton = true, // 닫기 버튼 표시 여부
}) => {
  const [isShow, setShow] = useState(isOpen);

  const bgStyleMatcher = {
    white: "bg-[#ffffff]",
    gradient: "bg-gradient-modal",
    dark: "bg-[#000000]",
  };

  const handleShow = () => setShow(!isShow);
  const handleActiveFunc = () => {
    if (activeFunc) activeFunc();
    setShow(false);
  };

  return (
    <div>
      {modalBtn && <div onClick={handleShow}>{modalBtn}</div>}
      {isShow && (
        <div
          className="fixed w-full h-full bg-[rgba(0,0,0,0.5)] top-0 right-0 z-[30] flex justify-center items-center"
          onClick={closeOnClickOutside ? handleShow : undefined} // 옵션 적용
        >
          <div
            className={`${bgStyleMatcher[backgroundStyle]} w-fit p-4 rounded-lg`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <div className="font-bold text-sm">{title}</div>
              {showCloseButton && ( // 옵션 적용
                <IoClose
                  onClick={handleActiveFunc}
                  className="w-5 h-5 cursor-pointer"
                />
              )}
            </div>
            <div>{content}</div>
            <div className="text-center">
              {activeBtnText && (
                <button
                  className="text-text-white bg-main-red min-w-[150px] p-1 rounded-md mt-3"
                  onClick={handleActiveFunc}
                >
                  {activeBtnText}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomModal;
