// hooks
import { useLocation } from "react-router-dom";
// react
import { Link, useNavigate } from "react-router-dom";
// assets
import vertical_logo from "assets/logo/vertical_logo.png";

const LandingHeader = () => {
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/");
  };
  const currentLocation = useLocation().pathname;
  return (
    <header
      className={`fixed top-0 z-20 w-screen select-none
     ${
       currentLocation === "/Landing" || currentLocation === "/landing"
         ? ""
         : "bg-text-white"
     }
    `}
    >
      <div
        className={`max-w-[1144px] flex justify-between items-center m-auto py-2`}
      >
        <div>
          <Link to="/landing">
            <img
              src={vertical_logo}
              alt="vertical_logo"
              className="w-[127px]"
            />
          </Link>
        </div>
        <Link to="/login">
          <button
            onClick={handleBtn}
            className="px-4 py-2 text-white rounded-xl bg-main-red"
          >
            바로 시작하기
          </button>
        </Link>
      </div>
    </header>
  );
};

export default LandingHeader;
