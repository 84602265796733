import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IoIosArrowDown } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa6';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { PLACE } from 'utils/consts/apiEndpoint';
import { RiDeleteBin6Line } from 'react-icons/ri';

export default function PlaceDropdown({
    curSeq = '',
    backgroundStyle = '#ffffff',
    handleOptions,
    handleInitAutoOptions,
    placeholderValue = '선택 안함',
    placeCategory = 'HOSPITAL',
}) {
    const [myPlace, setMyPlace] = useState(''); // 기본값을 null로 설정

    const getPlaceList = useApiQuery(['getPlaceList', placeCategory], PLACE.placeCategory(placeCategory), 'get');
    const getPlaceDetail = useApiQuery(['getPlaceDetail', myPlace?.seq], PLACE.placeSeq(myPlace?.seq), 'get', {
        enabled: !!myPlace?.seq,
    });
    const deletePlace = useApiMutation((seq) => PLACE.placeSeq(seq), 'delete', {
        onSuccess: (data) => {
            if (myPlace?.seq === data?.seq) {
                setMyPlace(null); // 선택값 초기화
                if (handleInitAutoOptions) handleInitAutoOptions();
            }
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '내 장소 삭제에 실패했습니다.';
        },
    });
    const handleDelete = (seq) => {
        if (!deletePlace?.isPending) deletePlace?.mutate(seq);
    };

    useEffect(() => {
        if (handleOptions) handleOptions(getPlaceDetail?.data);
    }, [myPlace?.seq, getPlaceDetail?.data]);
    useEffect(() => {
        if (curSeq && getPlaceList?.data?.data) {
            const matchedPlace = getPlaceList.data.data.find((place) => place.seq === curSeq);
            if (matchedPlace) {
                setMyPlace(matchedPlace);
            }
        }
    }, [curSeq, getPlaceList?.data]); // curSeq 또는 장소 목록 변경 시 실행

    return (
        <Box
            sx={{
                background: backgroundStyle,
                border: '1px solid #858e9630',
                borderRadius: '10px',
                position: 'relative',
            }}
        >
            <FormControl sx={{ width: '100%' }}>
                <Select
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '13px',
                        color: '#858e96 !important',
                        width: '100%',
                        '& .MuiSelect-select': {
                            padding: '10px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '.MuiSelect-select>svg': {
                            display: 'none',
                        },
                    }}
                    labelId="demo-simple-select-label"
                    value={myPlace} // 기본값이 없으면 null
                    IconComponent={IoIosArrowDown}
                    onChange={(e) => setMyPlace(e.target.value)}
                    displayEmpty // 기본 텍스트 표시
                    renderValue={(selected) => (selected ? selected.name : placeholderValue)} // 선택된 값이 없으면 "선택 안함" 표시
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: '13rem',
                                borderRadius: '15px',
                            },
                        },
                    }}
                >
                    {getPlaceList?.data?.data?.map((el, idx) => (
                        <MenuItem
                            key={idx}
                            value={el}
                            sx={{
                                fontSize: '13px',
                                borderRadius: '5px',
                                color: el === myPlace ? '#000000' : '#858e96',
                                background: el === myPlace ? '#F1F3F5 !important' : '#ffffff !important',
                                '&:hover': {
                                    background: '#F1F3F5 !important',
                                },
                                paddingY: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span className="flex justify-between gap-[2px] items-center">
                                {el?.name}
                                {el?.seq === myPlace?.seq && <FaCheck className="ml-2 text-main-pink" />}
                            </span>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(el?.seq);
                                }}
                                className="cursor-pointer ml-[10px]"
                            >
                                <RiDeleteBin6Line className="w-[16px] h-[16px] text-gray-gray hover:text-main-pink" />
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
