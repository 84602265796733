// hooks
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { IoClose } from 'react-icons/io5';
import { FaSearch } from 'react-icons/fa';
// components
import Map from 'components/NaverMap/Map';
import OnlyCircleRadio from 'components/Radio/OnlyCircleRadio';
import TimeLoadingModal from 'pages/BehanceEditor/components/Modals/TimeLoadingModal';
import Loading from 'components/Loading/Loading';
// utils
import { PLACE, EXTERNAL_API } from 'utils/consts/apiEndpoint';

const SearchMapModal = ({ title = '장소 세팅하기', btn, isShow = false, setIsShow, handleOptions }) => {
    const mapRef = useRef();
    const [inputKeyword, setInputKeyword] = useState('');
    const [fixedKeyword, setFixedKeyword] = useState('');
    const [placeData, setPlaceData] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [placeLoading, setPlaceLoading] = useState(false);

    const fetchPlaceId = async (query, coords) => {
        setPlaceLoading(true);
        try {
            const response = await axios.get(EXTERNAL_API.proxedInstantSearch, {
                params: {
                    query: query,
                    coords: coords,
                },
                headers: {
                    'User-Agent':
                        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36',
                    Referer: 'https://map.naver.com/',
                },
            });

            // console.log('네이버 지도 검색 결과:', response.data);
            setPlaceData(response.data.place); // 검색 결과 저장
        } catch (error) {
            console.error('API 요청 중 오류 발생:', error);
        } finally {
            setPlaceLoading(false);
        }
    };

    const postPlace = useApiMutation(PLACE.place, 'post', {
        onSuccess: (data) => {
            handleOptions(data);
            setIsShow(false);
            setFixedKeyword('');
            setInputKeyword('');
            setPlaceData([]);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            console.log(detailError);
        },
    });
    const handlePost = () => {
        if (!selectedPlace?.id) {
            alert('올바른 장소를 선택해주세요');
            return;
        }
        if (!postPlace?.isPending)
            postPlace.mutate({
                placeCategory: 'HOSPITAL',
                url: `https://map.naver.com/p/entry/place/${selectedPlace?.id}`,
            });
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setFixedKeyword(inputKeyword);
        }
    };
    useEffect(() => {
        if (fixedKeyword) {
            // 한번 Local 로 검색한 뒤에 가져와야 겠구나
            fetchPlaceId(fixedKeyword, '37,127');
        } else {
            setPlaceData([]);
        }
    }, [fixedKeyword]);

    return (
        <>
            {placeLoading && <Loading />} {/* ✅ 로딩 중일 때만 Loading 컴포넌트 표시 */}
            {postPlace?.isPending && (
                <TimeLoadingModal
                    second={30}
                    description="장소 정보 생성까지"
                />
            )}
            <button
                className=""
                onClick={() => setIsShow(!isShow)}
            >
                {btn}
            </button>
            {isShow && (
                <div className="fixed left-0 top-0 items-center bg-[#00000030] z-[30] w-screen h-screen flex justify-center">
                    <div className="p-[20px] bg-gradient-modal rounded-[15px]">
                        <div className="flex justify-between">
                            <div className="text-bold14">{title}</div>
                            <button
                                onClick={() => {
                                    setIsShow(false);
                                }}
                            >
                                <IoClose />
                            </button>
                        </div>
                        <div className="grid grid-cols-2 mt-[10px] gap-[15px] bg-white rounded-[10px] p-[10px]">
                            <div>
                                <div
                                    onKeyDown={handleKeyDown}
                                    className="relative defaultInputStyle"
                                >
                                    <input
                                        className="focus:outline-none w-[90%]"
                                        placeholder="장소 명을 검색해주세요"
                                        value={inputKeyword}
                                        onChange={(e) => setInputKeyword(e.target.value)}
                                    />
                                    <button
                                        onClick={() => {
                                            setFixedKeyword(inputKeyword);
                                        }}
                                        className="absolute right-0 w-[24px] h-[24px] top-0 m-[8px]"
                                    >
                                        <FaSearch />
                                    </button>
                                </div>
                                <div className="w-full h-[400px] mt-[10px] ">
                                    <div
                                        className="w-full "
                                        ref={mapRef}
                                    >
                                        <Map
                                            placeData={placeData}
                                            customstyle={{ width: '400px', height: '400px', borderRadius: '10px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="h-[40px] flex items-center w-full text-bold14">
                                    <span>검색결과</span>
                                    <span className="ml-[3px] text-main-red">{placeData?.length}</span>
                                </div>
                                <div className="h-[400px] overflow-y-scroll mt-[10px]">
                                    {placeData?.map((el) => (
                                        <div
                                            key={el?.id}
                                            onClick={() => setSelectedPlace(el)}
                                            className="border-t cursor-pointer border-gray-lGray"
                                        >
                                            <div className="flex items-center gap-[10px] py-[10px]">
                                                <OnlyCircleRadio checked={el?.id === selectedPlace?.id} />{' '}
                                                <span className="text-bold14">{el?.title}</span>
                                                <span className="text-regular14 text-gray-gray">{el?.ctg || ''}</span>
                                            </div>
                                            <div className="text-regular14 mb-[10px]">{el?.roadAddress}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <button
                                onClick={handlePost}
                                className="text-white text-bold14 bg-main-red mt-[10px] text-center rounded-[8px] w-[150px] h-[32px]"
                            >
                                사용
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default SearchMapModal;
