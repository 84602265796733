import AddCard from "components/Card/AddCard";
import ContentsCard from "components/Card/ContentsCard";
import React, { useState, useEffect, useCallback } from "react";
import {
  useRequestGet,
  useRequestDelete,
  useRequestPost,
} from "hooks/useRequest";
import { FiFolderPlus, FiTrash2 } from "react-icons/fi";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import CustomModal from "components/Modals/CustomModal";
import tutorial_brand from "assets/tutorial/setTutorial_brand.png";
const BrandSettings = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [contentData, setContentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const brandContent = useRequestGet(`api/brand?page=${page}&size=19`, [
    "brandContent",
    page,
  ]);

  const fetchData = useCallback(() => {
    if (brandContent.status === "success") {
      setContentData(brandContent.data.data.data);
      setTotalPages(brandContent.data.data.pageInfo.totalPages);
      setIsLoading(false); // 데이터 로드 완료
    } else if (brandContent.status === "error") {
      setIsLoading(false); // 데이터 로드 완료
    }
  }, [brandContent]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [brandContent.status, fetchData]);

  useEffect(() => {
    if (location.state?.newBrandAdded) {
      queryClient.invalidateQueries("brandContent");
    }
  }, [location.state, queryClient]);

  const deleteBrandMutation = useRequestDelete("brandContent");

  const handleDelete = (id) => {
    deleteBrandMutation.mutate(
      { url: `/api/brand/${id}` },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("brandContent").then(() => {
            fetchData();
            toast.success("브랜드가 성공적으로 삭제되었습니다!");
          });
        },
      }
    );
  };

  const brandClone = useRequestPost();

  const handleClone = (id) => {
    brandClone.mutate(
      {
        requestUrl: `/api/brand/clone/${id}`,
        data: id,
      },

      {
        onSuccess: () => {
          queryClient.invalidateQueries("brandContent");
          toast.success("브랜드가 성공적으로 복제되었습니다!");
        },
      }
    );
  };

  const itemList = [
    {
      label: "브랜드 복제",
      icon: (
        <FiFolderPlus className="w-4 h-4 text-gray-gray ml-2 group-hover:text-main-red" />
      ),
      selectedIcon: <FiFolderPlus className="w-4 h-4 text-main-red ml-2" />,
      onClick: (id) => handleClone(id),
    },
    {
      label: "브랜드 삭제",
      icon: (
        <FiTrash2 className="w-4 h-4 text-gray-gray ml-2 group-hover:text-main-red" />
      ),
      selectedIcon: <FiTrash2 className="w-4 h-4 text-main-red ml-2" />,
      onClick: (id) => handleDelete(id),
    },
  ];

  //튜토리얼
  const [showTutorial, setShowTutorial] = useState(false);
  useEffect(() => {
    const brandClickedSetting = localStorage.getItem("brandClickedSetting");

    if (!brandClickedSetting) {
      setShowTutorial(true); // 클릭한 적 없으면 모달 띄우기
    }
  }, []);

  const handleTutorialClose = () => {
    localStorage.setItem("brandClickedSetting", "true"); // 버튼 클릭 기록 저장
    setShowTutorial(false);
    navigate("/setting/brand");
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showTutorial && (
            <CustomModal
              isOpen={true}
              title="브랜드 설정"
              content={
                <div>
                  <img
                    src={tutorial_brand}
                    alt="셋팅 튜토리얼"
                    className="w-[400px] mt-2"
                  />
                  <div className="text-center bg-text-white p-2 rounded-lg mt-2 text-sm/6">
                    <h4 className="font-semibold">
                      <span className="text-main-red">01.</span> 운영하는 브랜드
                      설정
                    </h4>
                    <p>
                      브랜드 이름부터 카테고리, 설명, 연관 키워드를
                      <br />
                      입력 후 저장만 해두면 콘텐츠 제작시 편하게 불러올 수
                      있습니다.
                    </p>
                  </div>
                </div>
              }
              activeBtnText="설정하기"
              activeFunc={handleTutorialClose}
              backgroundStyle="gradient"
              closeOnClickOutside={true}
              showCloseButton={true}
            />
          )}
          <div className="mt-4 flex flex-wrap gap-4 pb-10">
            <AddCard name={"브랜드"} targetPath={"/setting/brand"} />
            {contentData.map((el, idx) => (
              <ContentsCard
                key={idx}
                date={el.modifiedAt}
                title={el.brandName}
                content={el.brandKeywords}
                btnTit={"수정하기"}
                itemList={itemList}
                id={el.seq}
                type={"brand"}
                onDelete={handleDelete}
              />
            ))}
          </div>
          <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        </>
      )}
    </div>
  );
};
export default BrandSettings;
