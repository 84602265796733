import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'api/axios';
// import axios from 'axios';
// import axiosFormDataInstance from 'api/axiosFormDataInstance';
// redux
// import { useDispatch } from 'react-redux';
// import { setAuthState } from '../store/authSlice';
// import { setUserInfoState } from 'store/userInfoSlice';

export const useApiQuery = (
    key,
    url,
    method = 'get',
    options = {
        refetchOnWindowFocus: false, // 기본값으로 포커스 리페치를 방지
        staleTime: 60000, // 1분 동안 데이터 신선도 유지
    },
    params
) => {
    return useQuery({
        queryKey: key,
        queryFn: async () => {
            const response = await axiosInstance({
                url,
                method,
                params,
            });
            if (response?.status === 200) return response.data;
            else if (response?.status !== 200) {
                alert('200응답이 아님!');
                return response.data;
            }
            return response;
        },
        // 캐시 무효화 추가 옵션
        retry: 1, // 재시도
        refetchOnWindowFocus: false, // 창 포커스 시 재요청 방지
        ...options,
    });
};
// type ApiMethod = 'get' | 'post' | 'patch' | 'delete';
export const useApiMutation = (
    url, //  (props) => ENDPOINT_ADMIN.approval(props.userId) 이런식으로 사용
    method = 'post',
    options = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data) => {
            const resolvedUrl = typeof url === 'function' ? url(data) : url;
            const response = await axiosInstance({
                url: resolvedUrl,
                method,
                data,
            });
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            if (options.onSuccess) {
                options.onSuccess(data);
            }
        },
        onError: (error) => {
            if (options.onError) {
                const errorMessage = error.reason || error.message || '알 수 없는 오류가 발생했습니다.';
                console.log(`❌ ${errorMessage}`);
                options.onError(errorMessage);
            } else {
                console.error('🅰️ API Error:', error);
            }
        },
    });
};

// export const useLoginMutation = (url, method = 'post', options = {}) => {
//     const dispatch = useDispatch();

//     return useMutation({
//         mutationFn: async (data) => {
//             const response = await axiosInstance({
//                 url,
//                 method,
//                 data,
//             });
//             return response; // 전체 응답 객체 반환
//         },
//         onSuccess: (response) => {
//             const accessToken = response.headers['authorization']; // 헤더에서 토큰 추출
//             const { refreshToken, name, id } = response.data;

//             if (accessToken) {
//                 // localStorage.setItem('accessToken', accessToken.replace('Bearer ', '')); // 저장
//                 dispatch(
//                     setAuthState({
//                         accessToken,
//                         refreshToken,
//                     })
//                 );
//                 dispatch(
//                     setUserInfoState({
//                         name,
//                         id,
//                         email: null,
//                         profileImageUrl: null,
//                         role: null, // null 허용
//                         staffRole: null, // 이미 null 허용됨
//                         status: null,
//                     })
//                 );
//             } else {
//                 console.warn('🚨 토큰이 응답 헤더에 없습니다.');
//             }

//             if (options.onSuccess) {
//                 options.onSuccess(response.data);
//             }
//         },
//         onError: (error) => {
//             const errorMessage =
//                 error?.response?.data?.detailedMessage || error?.response?.data?.message || '로그인에 실패했습니다.';
//             if (options.onError) {
//                 options.onError(errorMessage);
//             } else {
//                 console.error('로그인 에러:', error);
//             }
//         },
//     });
// };

// export const useApiFormDataMutation = (url, method: ApiMethod = 'post', options: MutationOptions = {}) => {
//     const queryClient = useQueryClient();

//     return useMutation({
//         mutationFn: async (data: any) => {
//             const response = await axiosFormDataInstance({
//                 url,
//                 method,
//                 data,
//             });
//             return response.data;
//         },
//         onSuccess: (data) => {
//             queryClient.invalidateQueries();
//             if (options.onSuccess) {
//                 options.onSuccess(data);
//             }
//         },
//         onError: (error: any) => {
//             if (options.onError) {
//                 const errorMessage = error.reason || error.message || '알 수 없는 오류가 발생했습니다.';
//                 console.log(`❌ ${errorMessage}`);
//                 options.onError(errorMessage);
//             } else {
//                 console.error('🅰️ API Error:', error);
//             }
//         },
//     });
// };
