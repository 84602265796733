// hooks
import { useEffect, useState } from 'react';
import { useRequestGet, useRequestPost } from 'hooks/useRequest';
import { useNavigate, useSearchParams } from 'react-router-dom';
// common components

import ProjectCard from 'components/Card/ProjectCard';
import BasicModal from 'components/Modals/BasicModal';
import NavigateHeader from 'layout/NavigateHeader';

// icons
import { FiFilePlus } from 'react-icons/fi';
// utils
import { templateQuestions } from 'utils/TemplateQuestions';

const Contents = () => {
    // 기존 데이터 가져오기
    const existingData = JSON.parse(localStorage.getItem('navRecord') || '{}');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');
    const template = searchParams.get('template');
    const [templateEl, setTemplateEl] = useState(null);
    const [contents, setContents] = useState([]);
    const getProjectDetail = useRequestGet(`/api/project/${projectSeq}`, `getProject-${projectSeq}`);
    const getContents = useRequestGet(`/api/project/${projectSeq}/content`, `getContents-${projectSeq}`);
    const handleContentRefetch = () => {
        getContents.refetch();
    };
    useEffect(() => {
        if (getContents?.data) {
            setContents(getContents?.data?.data?.data);
        }
    }, [getContents?.data]);

    useEffect(() => {
        if (template) {
            const selectedTemplate = templateQuestions.find((t) => t.templateEN === template);
            if (selectedTemplate) {
                setTemplateEl(selectedTemplate);
            }
        }
    }, [template]);

    return (
        <div className="pb-10 wrapper">
            <div className="flex flex-wrap justify-center cherry-wrapper ">
                <NavigateHeader />

                {contents && (
                    <div className="flex flex-wrap w-full mt-3">
                        <div className="inline-block m-[7px]">
                            <div className="flex flex-col justify-center border-dashed items-center border-2 border-gray-gray text-sm  rounded-[18px] w-[214px] h-[214px]">
                                <button
                                    className="text-gray-gray"
                                    onClick={() => {
                                        localStorage.setItem(
                                            'navRecord',
                                            JSON.stringify({ ...existingData, content: '콘텐츠' })
                                        );
                                        template && templateEl
                                            ? navigate(`/templates/project/content/template?projectSeq=${projectSeq}`, {
                                                  state: templateEl,
                                              })
                                            : navigate(`/project/content/templates?projectSeq=${projectSeq}`);
                                    }}
                                >
                                    <FiFilePlus className="w-5 h-5 mx-auto mt-3 mb-2" />
                                    <div>콘텐츠 추가</div>
                                </button>
                            </div>
                        </div>
                        {contents.map((el, idx) => (
                            <div
                                className="m-[7px] relative"
                                key={idx}
                            >
                                <ProjectCard
                                    tab="content"
                                    seq={el.seq}
                                    title={el.contentName}
                                    date={el.modifiedAt && el.modifiedAt}
                                    btnTit={'입장하기'}
                                    hasContents={el.hasContents}
                                    refetchFunc={handleContentRefetch}
                                    navigateUrl={`/behance-editor?projectSeq=${projectSeq}&contentSeq=${el.seq}`}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contents;
