import { useState, useEffect } from 'react';

const BasicRadio = ({ optionKey, checked }) => {
    return (
        <>
            <span
                className={`w-5 relative h-5 inline-block border-2 rounded-full ${
                    checked ? 'border-main-red' : 'border-gray-gray'
                }`}
            >
                {checked && (
                    <span className="w-2.5 h-2.5 bg-main-red rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-transform duration-300"></span>
                )}
            </span>
        </>
    );
};

export default BasicRadio;
