import { useEffect, useRef } from 'react';

const NaverMap = ({ placeData = [], customstyle }) => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // 지도 인스턴스를 저장할 ref
    const markersRef = useRef([]); // 마커 배열을 저장할 ref

    useEffect(() => {
        if (!window.naver) {
            console.error('Naver Maps API가 로드되지 않았습니다.');
            return;
        }
        // 📌 서울 기본 좌표 (위도: 37.5665, 경도: 126.9780)
        const defaultCenter = new window.naver.maps.LatLng(36.5, 127.5);

        // placeData가 있을 경우 첫 번째 장소의 위치를 사용, 없으면 서울 기본 좌표 사용
        const initialCenter =
            placeData.length > 0
                ? new window.naver.maps.LatLng(Number(placeData[0].y), Number(placeData[0].x))
                : defaultCenter;

        // 지도가 이미 존재하면 다시 만들지 않음
        if (!mapInstance.current) {
            mapInstance.current = new window.naver.maps.Map(mapRef.current, {
                center: initialCenter,
                zoom: 7,
            });
        }

        const map = mapInstance.current;

        // 기존 마커 제거
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];

        // 새로운 마커 추가
        placeData.forEach((place) => {
            const marker = new window.naver.maps.Marker({
                position: new window.naver.maps.LatLng(Number(place.y), Number(place.x)),
                map: map,
                title: place.title,
            });

            const infoWindow = new window.naver.maps.InfoWindow({
                content: `
                    <div style="padding:8px; font-size:14px; max-width:200px;">
                        <strong>${place.title}</strong><br />
                        ${place.ctg}<br />
                        ${place.roadAddress}
                    </div>
                `,
            });

            // 마커 클릭 이벤트 등록
            window.naver.maps.Event.addListener(marker, 'click', () => {
                map.setCenter(marker.getPosition());
                infoWindow.open(map, marker);
            });

            markersRef.current.push(marker);
        });
    }, [placeData]);

    return (
        <div
            ref={mapRef}
            style={customstyle}
        />
    );
};

export default NaverMap;
